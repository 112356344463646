<script>
    import MessageBox from "@/components/MessageBox.svelte";
    import { fetchTranslation } from "@/common/i18n.js";
    import { formValidationErrors } from "@/stores/flash.js";

    export let name;
</script>

<div>
    <slot/>
    {#if $formValidationErrors[name]}
        {#each $formValidationErrors[name] as error}
            <MessageBox type="error">
                {#if error.includes(' ')}
                    {@html error}
                {:else}
                    {#await fetchTranslation(`aid.errors.field_error.${name}.${error}`) then message}
                        {@html message}
                    {/await}
                {/if}
            </MessageBox>
        {/each}
    {/if}
</div>
